* {
  padding: 0;
  margin: 0;
}

html {
  background-color: #000;
}

.App {
  color: white;
}

.Wrap {
  width: 1180px;
  max-width: 90%;
  margin: 0 auto;
}