.TransferStatus-parent {
  width: 100%;
  margin: 0 auto;
}

.TransferStatus-progress {
  height: 2.5em;
  background-color: #000;
  border-radius: 6px;
  border: 2px solid #fff;
  margin: 0.25em 0.25em 2em 0.25em;
}

.TransferStatus-progressBar {
  height: 100%;
  background-color: #fff;
  border: 2px solid #fff;
  margin: -2px;
  border-radius: 6px;
  text-align: right;
}

.TransferStatus-text {
  color:#fff;
  font-weight: 600;
  padding: 0.5em;
  margin: 0.25em;
  width: 6em;
  display: inline-block;
  border-radius: 6px;
  border: 2px solid #fff;
  text-align: center;
}