.PinInput-element {
  border-radius: 6px;
  border: 2px solid #fff;
  color: #fff;
  background-color: #000;
  font-size: 24px;
  padding: 0.5rem;
  margin: 0.25rem;
  width: 14px;
  cursor: pointer;
}

.PinInput-parent {
  text-align: center;
  font-size: 30px;
}

/* Chrome, Safari, Edge, Opera */
.PinInput-element::-webkit-outer-spin-button,
.PinInput-element::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

/* Firefox */
.PinInput-element[type=number] {
-moz-appearance: textfield;
}

.PinInput-space {
  display: inline-block;
  margin: 0.5rem;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .PinInput-element {
    font-size: 16px;
    width: 10px;
  }
}