.Button {
    border-radius: 6px;
    border: 2px solid #fff;
    color: #fff;
    background-color: #000;
    text-align: center;
    font-size: 24px;
    padding: 0.5rem;
    width: 100%;
    transition: all 0.5s;
    cursor: pointer;
    margin: 0.25em 0;
}

.Button:hover {
    background-color: #fff;
    color: #000;
}

.Button:disabled{
  border: 2px solid #313131;
  background-color: #313131;
  color: #6e6e6e;
  cursor: not-allowed;
}