.Row {
  width: 100%;
  display: flex;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .row {
    display: block;
  }
}
