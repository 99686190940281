.AbortButton {
  border-radius: 6px;
  border: 2px solid #fff;
  color: #fff;
  background-color: #000;
  text-align: center;
  font-size: 24px;
  padding: 0.5rem;
  width: 100%;
  max-width: 500px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 0.25em auto;
}

.AbortButton:hover {
  background-color: rgb(255, 40, 40);
  color: rgb(255, 255, 255);
}