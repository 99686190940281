.PinDisplay-parent {
  width: 100%;
  max-width: 500px;
  display: flex;
  margin: 0 auto;
}

.PinDisplay-element {
  border-radius: 6px;
  border: 2px solid #fff;
  color: #fff;
  background-color: #000;
  text-align: center;
  font-size: 24px;
  padding: 0.5rem;
  width: 100%;
  margin: 0.25rem 0.25rem;
}

.PinDisplay-space {
  margin: 1rem;
}

.PinDisplay-button {
  border-radius: 6px;
  border: 2px solid #fff;
  color: #fff;
  background-color: #000;
  text-align: center;
  font-size: 24px;
  padding: 0.25rem;
  width: 100%;
  transition: all 0.5s;
  cursor: pointer;
  margin: 0.25em;
}

.PinDisplay-button:hover {
  background-color: #fff;
  color: #000;
}