.ErrorCard-element {
  text-align: left;
  border-radius: 10px;
  border: 2px solid #fff;
  margin: 1rem;
  margin-bottom: 0;
  width: calc(100% - 4px - 2rem);
}

.ErrorCard-element > .ErrorCard-content {
  padding: 0.5rem 3rem 1rem 3rem;
}

.ErrorCard-element h1 {
  padding: 1rem 3rem 0rem 3rem;
  font-family: "Times New Roman";
  font-weight: 400;
  display: inline-block;
}

.ErrorCard-element button {
  padding: 0.5em;
  float: right;
  margin: 1rem;
  border-radius: 6px;
  border: 2px solid #fff;
  color: #fff;
  background-color: #000;
  transition: all 0.5s;
  cursor: pointer;
}

.ErrorCard-element button:hover {
  background-color: #fff;
  color: #000;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .ErrorCard-element {
    margin: 0.5rem 0;
    width: 100%;
  }

  .ErrorCard-element > .ErrorCard-content {
    padding: 1rem 2rem;
  }
}
