.Card {
  text-align: left;
  border-radius: 10px;
  border: 2px solid #fff;
  margin: 1rem;
}

/* removes 2 Pixel Border times 2 so that they fit in wrap */
.Card-col-4 {
  width: calc(25% - 4px - 2rem);
}
.Card-col-3 {
  width: calc(33.33% - 4px - 2rem);
}
.Card-col-2 {
  width: calc(50% - 4px - 2rem);
}
.Card-col-1 {
  width: calc(100% - 4px - 2rem);
}

.Card > .Card-content-without-title {
  padding: 1rem 2rem 1rem 2rem;
}

.Card > .Card-content-with-title {
  padding: 0.5rem 3rem 2rem 3rem;
}

.Card h1 {
  padding: 2rem 3rem 0rem 3rem;
  font-family: "Times New Roman";
  font-weight: 400;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .Card {
    margin: 0.5rem 0;
  }

  [class*="col-"] {
    width: 100%;
  }

  .Card > .Card-content {
    padding: 1rem 2rem;
  }
}